const localGeoData = localStorage.getItem('currentGeoLocation')
const initialConfig = {
    currentGeoLocation: localGeoData ? JSON.parse(localGeoData) : null,
}

const initialLearningState = {
    items: []
}

export const myLearning = (state = initialLearningState, action) => {
    switch (action.type) {
        case 'SET_MY_LEARNING_DATA':
            return { ...state, items: action.payload }
        default:
            return { ...state }
    }
}


export const config = (state = initialConfig, action) => {
    switch (action.type) {
        case 'SET_GEO_LOCATION':
            localStorage.setItem('currentGeoLocation', JSON.stringify(action.payload))
            return { ...state, currentGeoLocation: action.payload }
        default:
            return { ...state }
    }
}

let secretToken = localStorage.getItem('secretToken')
let userData = secretToken ? localStorage.getItem('userData') : null

if (!secretToken) {
    localStorage.removeItem('userData')
    userData = null
}
if (!userData) {
    localStorage.removeItem('secretToken')
    secretToken = null
}

const userInitialState = {
    isLoading: false,
    timer: false,
    registerFormData: null,
    registerUser: userData,
    isOtpStep: false,
    otpVerified: false,
    isMentor: false,
    error: '',
    isLoggedIn: !!secretToken,
    UData: null,
    isDetailStep: false,
    token: '',
}

const assetState = {
    isLoading: false,
    totalPages: 0,
    items: [],
    industryList: [],
    domainList: [],
    interestList: [],
}

export const assetData = (state = assetState, action) => {
    switch (action.type) {
        case 'SET_WISHLIST_DATA':
            return { ...state, items: action.payload }
        case 'SET_WISHLIST_LOADING': {
            return { ...state, isLoading: true }
        }
        case 'SET_WISHLIST_LOADING_FALSE': {
            return { ...state, isLoading: false }
        }
        case 'SET_INDUSTRIES':
            return { ...state, industryList: action.payload }
        case 'SET_DOMAINS':
            return { ...state, domainList: action.payload }
        case 'SET_INTERESTS':
            return { ...state, interestList: action.payload }
        default:
            return { ...state }
    }
}


export const user = (state = userInitialState, action) => {
    switch (action.type) {
        case 'USER_REGISTER_LOADING':
            return { ...state, isLoading: true }
        case 'SET_OTP_STEP':
            return { ...state, isOtpStep: true, timer: true, isLoading: false, error: null, UData: JSON.stringify(action.payload) }
        case 'SET_OTP_VERIFIED':
            localStorage.setItem('otpVerified', 'true')
            return {
                ...state,
                isOtpStep: false,
                otpVerified: true,
                isLoading: false,
                error: null,
            }
        case 'RESET_OTP_STEP':
            localStorage.removeItem('secretToken')
            localStorage.removeItem('otpVerified')
            localStorage.removeItem('userData')
            return {
                ...state,
                isOtpStep: false,
                isDetailStep: false,
                registerUser: null,
                timer: false,
                isLoggedIn: false,
            }
        case 'SET_USER_REGISTER_FORM_DATA':
            return { ...state, registerFormData: action.payload }
        case 'SET_USER_REGISTER_DATA':
            // localStorage.setItem(
            //   'secretToken',
            //   `bearer ${action.payload.tokens.token}`,
            // )
            // localStorage.setItem('userData', JSON.stringify(action.payload.data))
            return {
                ...state,
                token: action.payload.tokens.token,
                registerUser: action.payload,
                isLoading: false,
                error: null,
                isDetailStep: true,
            }
        case 'IS_MENTOR':
            return { ...state, isMentor: true }
        case 'SET_TOKEN': {
            localStorage.setItem(
                'secretToken',
                `bearer ${action.payload.tokens.token}`,
            )
            return { ...state }
        }
        case 'DETAIL_SUBMIT':
            localStorage.setItem(
                'secretToken',
                `bearer ${state.token}`,
            )
            localStorage.setItem('userData', JSON.stringify(action.payload.data))
            return { ...state, isLoading: false, isDetailStep: false, isLoggedIn: true, error: null }
        case 'SOCIAL_DETAIL_STEP':
            return {
                ...state,
                isLoading: false,
                isDetailStep: true,
                registerUser: action.payload,
                isSocial: true,
                registerFormData: null
            }
        case 'SOCIAL_OTP_SENT':
            return { ...state, isOtpStep: true, isLoading: false }
        case 'SOCIAL_OTP_FAILED':
            return { ...state, isLoading: false }
        case 'DETAIL_ERROR':
            return { ...state, isLoading: false }
        case 'SET_USER_REGISTER_ERROR':
            localStorage.setItem('userData', '')
            return {
                ...state,
                registerUser: null,
                isLoading: false,
                error: action.payload,
            }
        case 'SET_USER_LOGIN_DATA':
            localStorage.setItem(
                'secretToken',
                `bearer ${action.payload.credentials.token}`,
            )
            localStorage.setItem('userData', JSON.stringify(action.payload))
            return {
                ...state,
                registerUser: action.payload,
                otpVerified: true,
                isLoading: false,
                error: null,
                isLoggedIn: true,
            }
        case 'SET_USER_LOGIN_ERROR':
            return {
                ...state,
                isLoading: false,
                registerUser: null,
                error: action.payload,
            }
        case 'SET_USER_OTP_ERROR':
            return { ...state, isLoading: false, error: action.payload }
        case 'RESET_SOCIAL_OTP':
            return { ...state, isOtpStep: false }
        case 'RESEND_OTP':
            return { ...state, isLoading: false, timer: false }
        case 'RESEND_OTP_FAILED':
            return { ...state, isLoading: false, error: action.payload }
        case 'LOGOUT':
            localStorage.clear()
            return { ...userInitialState, isLoggedIn: false }
        default:
            return { ...state }
    }
}
