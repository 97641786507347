import { useEffect } from 'react';

const useBodyOverflow = (isModalVisible) => {
  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalVisible]);
};

export default useBodyOverflow;
