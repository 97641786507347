import axios from "axios"
import toast from 'react-hot-toast'

import {
    GET_CURRENT_LOCATION,
    USER_LOGIN,
    USER_REGISTER,
    VERIFY_OTP,
    LEAD_URL,
    LEAD_DATA_URL,
    SIGNUP_DETAILS,
    GENERATE_PAYMENT_URL,
    ADD_TO_WISHLIST,
    GET_WISHLIST,
    REMOVE_FROM_WISHLIST,
    FETCH_LEARNING_ALL,
    CHECK_IN_WISHLIST,
    FETCH_INDUSTRIES,
    FETCH_DOMAIN_BY_INDUSTRY,
    FETCH_INTERESTS,
    SOCIAL_OTP,
    GET_AICTE_PAYMENT,
    
} from "./endpoints"

export const getAllMyLearningData = (id) => async dispatch => {
    await axios.get(FETCH_LEARNING_ALL(id), {
        headers: getAuthHeader()
    }).then((response) => {
        dispatch({ type: 'SET_MY_LEARNING_DATA', payload: response.data.data })
    }).catch((err) => {
        console.log(err)
    }
    )
}

export const getIndustries = () => async dispatch => {
    await axios.get(FETCH_INDUSTRIES, {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'SET_INDUSTRIES', payload: res.data.data })
    }).catch((err) => {
        console.log(err)
    })
}

export const getDomains = (id) => async dispatch => {
    await axios.get(FETCH_DOMAIN_BY_INDUSTRY(id), {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'SET_DOMAINS', payload: res.data.data })
    }).catch((err) => {
        console.log(err)
    })
}

export const getInterests = () => async dispatch => {
    await axios.get(FETCH_INTERESTS, {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'SET_INTERESTS', payload: res.data.data })
    }).catch((err) => {
        console.log(err)
    })
}




export const getAuthHeader = () => {
    const token = localStorage.getItem('secretToken')
    const headers = { Authorization: token }
    return headers
}

export const getGeoLocation = () => (dispatch, getState) => {
    const { config: { currentGeoLocation } = {} } = getState() || {}
    const details =  {
     country_code:"IN",
     country_name:"India",
    }
    if (!currentGeoLocation) {
        axios.get(GET_CURRENT_LOCATION)
        .then(({ data }) => {
        !!data?.latitude 
        ? dispatch({ type: "SET_GEO_LOCATION", payload: data })
        : localStorage.setItem('currentGeoLocation', JSON.stringify(details))
        })
      .catch((err) => {
      localStorage.setItem('currentGeoLocation', JSON.stringify(details))
       });
     }
  }

export const addToWishList = data => async dispatch => {
    dispatch({ type: 'SET_WISHLIST_LOADING' })
    await axios.post(ADD_TO_WISHLIST, data, {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'ADD_WISHLIST_DATA', payload: res.data.data })
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    }).catch((err) => {
        console.log(err)
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    })
}

export const getWishList = () => async dispatch => {
    dispatch({ type: 'SET_WISHLIST_LOADING' })
    await axios.get(GET_WISHLIST, {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'SET_WISHLIST_DATA', payload: res.data.data })
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    }).catch((err) => {
        console.log(err)
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    })
}

export const removeFromWishList = id => async dispatch => {
    dispatch({ type: 'SET_WISHLIST_LOADING' })
    await axios.get(REMOVE_FROM_WISHLIST(id), {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'REMOVE_WISHLIST_DATA', payload: res.data.data })
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    }).catch((err) => {
        console.log(err)
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    })
}

export const checkInWishlist = data => async dispatch => {
    dispatch({ type: 'SET_WISHLIST_LOADING' })
    return await axios.post(CHECK_IN_WISHLIST, data, {
        headers: getAuthHeader()
    }).then((res) => {
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
        return res.data.data
    }).catch((err) => {
        console.log(err)
        dispatch({ type: 'SET_WISHLIST_LOADING_FALSE' })
    })
}

export const getPaymentURL = data => async dispatch => {
    return await axios.post(GENERATE_PAYMENT_URL, data, {
        headers: getAuthHeader()
    }).then((res) => {
        return res.data.data
    }).catch((err) => {
        return Promise.reject(err)
    })
}

export const initiateUserRegistration = data => dispatch => {
    dispatch({ type: 'USER_REGISTER_LOADING' })
    dispatch({ type: 'SET_USER_REGISTER_FORM_DATA', payload: data })
    axios
        .post(USER_REGISTER, data)
        .then(({ data }) => {
            if (data?.recaptchaStatus == false) {
                toast.error('Oops! Seems like some suspicious activity, Try again.')
                return;
            }
            if (data?.SMSStatus) {
                toast.success(
                    data?.data?.smsStatus?.message || 'OTP sent to your linked phone',
                    { style: { zIndex: 9999 } })
                dispatch({ type: 'SET_OTP_STEP', payload: data })
            } else {
                toast.error(data?.data?.smsStatus?.message || 'Please enter a valid Phone number.', { style: { zIndex: 99999 } })
                dispatch({ type: 'DETAIL_ERROR' })
            }
        })
        .catch(({ response: { data: error } }) => {
            toast.error(error.message || 'Cant send OTP to your phone', { style: { zIndex: 9999 } })
            dispatch({ type: 'SET_USER_REGISTER_ERROR', payload: error })
        })
}
export const LeadCapture = (values) => async dispatch => {
    let lead = [];
    for (const [key, value] of Object.entries(values)) {

        lead = [
            ...lead,
            {
                "Attribute": key,
                "Value": value
            }
        ]
    }

    return axios.post(LEAD_URL,
        lead,
    ).then((res) => {
        // console.log(res)
    }
    ).catch(error => {
        console.log(error.message);
    });
}
export const LeadDataCapture = (values) => async dispatch => {
    return axios.post(LEAD_DATA_URL,
        values, { headers: getAuthHeader() }
    ).then((res) => {
        // console.log(res)
    }
    ).catch(error => {
        console.log(error.message);
    });
}

export const mailModo = (data) => async dispatch => {
    try {
        const res = await axios.post(process.env.REACT_APP_MAIL_MODO_URL, data, {
            headers: {
                mmApiKey: process.env.REACT_APP_MAIL_MODO_KEY
            }
        });
        console.log(res);
    } catch (error) {
        console.log(error.message);
    }
}

export const initiateOtpVerification = data => (dispatch, getState) => {
    const {
        user: { registerFormData, isSocial },
    } = getState() || {}
    const { mobile_no, country_code } = registerFormData;
    dispatch({ type: 'USER_REGISTER_LOADING' })
    const apiData = {
        country_code,
        mobile_no,
        otp: data.otp,
        user_id: data.user_id
    }
    return axios
        .post(VERIFY_OTP, apiData)
        .then(({ data }) => {
            const { first_name, email, user_category, user_type, mobile_verified } = data.data.user || {}
            if (first_name && email && user_category && mobile_verified == 1) {
                if (user_type === 'MENTOR') {
                    dispatch({ type: 'IS_MENTOR' })
                }
                dispatch({ type: 'DETAIL_SUBMIT', payload: data })
                dispatch({ type: 'SET_TOKEN', payload: data })
                toast.success('Logged in successfully.')
                return;
            }
            dispatch({ type: 'SET_OTP_VERIFIED' })
            dispatch({
                type: 'SET_USER_REGISTER_DATA',
                payload: data,
            })
            if (isSocial) {
                toast.success('OTP verified successfully!')
            }
        })
        .catch(({ response: { data: error } }) => {
            toast.error(error.message || 'OTP verification error', { style: { zIndex: 9999 } })
            dispatch({ type: 'SET_USER_OTP_ERROR', payload: error })
        })
}

export const initiateSignUpDetails = data => (dispatch, getState) => {
    const { user: { token } } = getState() || {}
    dispatch({ type: 'USER_REGISTER_LOADING' })
    return axios.post(SIGNUP_DETAILS, data, { headers: { Authorization: `bearer ${token}` } }).then(({ data }) => {
        const { user_type } = data.data.user || {}
        if (user_type === 'MENTOR') {
            dispatch({ type: 'IS_MENTOR' })
        }
        dispatch({ type: 'DETAIL_SUBMIT', payload: data })
        toast.success('Logged in successfully.')
    }).catch((error) => {
        if (error.response) {
            const response = error.response
            toast.error(response?.data?.message || 'Something went wrong.', { style: { zIndex: 9999 } })
            dispatch({ type: 'DETAIL_ERROR' })
        } else {
            dispatch({ type: 'DETAIL_ERROR' })
            toast.error(error?.message || 'Something went wrong.', { style: { zIndex: 9999 } })
        }
    })
}

export const initiateSocialLogin = url => async (dispatch) => {
    dispatch({ type: 'USER_REGISTER_LOADING' })
    try {
        const { data } = await axios.get(url)
        const { first_name, email, user_category, user_type, mobile_verified } = data.data.user || {}
        if (first_name && email && user_category && mobile_verified == 1) {
            if (user_type === 'MENTOR') {
                dispatch({ type: 'IS_MENTOR' })
            }
            dispatch({ type: 'DETAIL_SUBMIT', payload: data })
            dispatch({ type: 'SET_TOKEN', payload: data })
            toast.success('Logged in successfully.')
        }
        dispatch({ type: 'SOCIAL_DETAIL_STEP', payload: data })
    } catch (err) {
        if (err?.response?.data?.message === 'Unable to find any email associated with this account! Try signing up with different approach via OTP or google') {
            toast.error(err?.response?.data?.message)
            window.location.href = "/"
        }
        console.log(err)
    }
}

export const initiateSocialRegister = data => async dispatch => {
    dispatch({ type: 'USER_REGISTER_LOADING' })
    dispatch({ type: 'SET_USER_REGISTER_FORM_DATA', payload: data })
    return axios.post(SOCIAL_OTP, data, {
        headers: getAuthHeader()
    }).then((res) => {
        if (res.data.SMSStatus) {
            dispatch({ type: 'SOCIAL_OTP_SENT' })
        } else {
            toast.error('Please enter a valid phone number')
            Promise.reject()
        }
    }).catch((err) => {
        toast.error(err?.response?.data?.message || 'Something went wrong')
        dispatch({ type: 'SOCIAL_OTP_FAILED' })
        console.log(err)
    })
}

export const resendOtp = () => (dispatch, getState) => {
    const {
        user: { registerFormData },
    } = getState() || {}
    // const { user: { country_id, mobile_no } = {} } = registerUser
    const { country_code, mobile_no } = registerFormData
    dispatch({ type: 'USER_REGISTER_LOADING' })
    const apiData = {
        country_code: country_code,
        mobile_no: mobile_no,
    }
    axios
        .post(USER_REGISTER, apiData)
        .then(({ data }) => {
            dispatch({ type: 'RESEND_OTP', payload: data })
            toast.success('OTP successfully resend at registered phone.', { style: { zIndex: 9999 } })
        })
        .catch(error => {
            if (error.response) {
                const response = error.response
                toast.error(response?.message || 'Something went wrong.', { style: { zIndex: 9999 } })
            } else {
                toast.error(error?.message || 'Something went wrong.', { style: { zIndex: 9999 } })
            }
        })
}


export const logout = () => dispatch => {
    dispatch({ type: 'LOGOUT' })
}

export const backToRegForm = () => ({
    type: 'RESET_OTP_STEP',
})

export const getAictePayment = (data) => async() => {
return axios.post(GET_AICTE_PAYMENT, data)
  .then((res) => {
    if(res?.data?.status) return Promise.resolve(res);
  })
  .catch((err) => {
    console.log(err);
    toast.error(err?.response?.data?.message || "Something went wrong while doing payment, kindly contact admin")
  })
}

